import React from 'react';
import Headerbar from '../HeaderPage.web';
import SideMenuBarWeb from '../Sidebar.web';
import { Button, Col, Layout, Row,Carousel } from 'antd';
import { bookingHeaderImg } from '../assets';
import Link from 'antd/lib/typography/Link';
import BookingController from "../Booking/BookingPage.controller.web"
import "./Booking.css"


const { Sider } = Layout;




const renderCarouselData = () => {
    return (
        <div className='booking-header' >
            <div>
                <div className='booking-text'>New Bookings</div>
                <div className='header-content'>From Cost saving to icreased collabration opportunities, coworking paces can make for ideal offices, especially for small and growing bussiness.</div>
                <Link href='/createBooking' ><Button className='book-btn' >Book</Button></Link>
            </div>
            <div className='header-image'><img src={bookingHeaderImg} /></div>
        </div>
    )
}


class Booking extends BookingController {
    render() {
        const renderImageColumns = () => {
            return (this.imageUrls.map((imgUrl: any, index: any) => (
                <Col data-test-id="handleImageButton" style={{ cursor: "pointer" }} onClick={() => { this.handleImg(imgUrl.title) }} key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Link href='/createBooking' ><img src={imgUrl.img} alt={`Image ${index + 1}`} /></Link>
                </Col>
            )))
        };
        return (
            <>
                <div style={{
                    background: "#76C043", width: "100%", position: "fixed",
                    top: "0px",
                    bottom: "0px",
                    display: "flex",
                    boxSizing: "border-box"
                }}>
                    <Layout>
                        <Sider>
                            <SideMenuBarWeb />
                        </Sider>
                        <Layout style={{ overflow: "auto", backgroundColor: "#76C043" }} >
                            <Headerbar />
                            <div className='booking-section'>
                                <Carousel autoplay >
                                    <div>
                                        <h3 className="contentStyle">{renderCarouselData()}</h3>
                                    </div>
                                    <div>
                                        <h3 className="contentStyle">{renderCarouselData()}</h3>
                                    </div>
                                    <div>
                                        <h3 className="contentStyle">{renderCarouselData()}</h3>
                                    </div>
                                </Carousel>
                                <Row justify="space-between" className='booking-data' >
                                    {renderImageColumns()}
                                </Row>
                            </div>
                        </Layout>
                    </Layout>
                </div>
            </>
        );
    }
}




export default Booking;


