import React from "react";
import { sidebarimage1, sidebarimage2, sidebarimage3, sidebarimage4, sidebarimage5 } from './assets';
import { Menu } from "antd";
import Title from "antd/lib/typography/Title";
import '../assets/bookingPage.css';
import { Link } from 'react-router-dom';
import "./Sidebar.css"
const sidebarimages = require('./assets')


const SideMenuBarWeb = () => {


  const menuItems = [
    { key: "dashboard", color: '#ACACAC', icon: sidebarimage1, label: "Dashboard", link: "/createbooking" },
    { key: "booking", color: '#ACACAC', icon: sidebarimage2, label: "Booking", link: "/booking" },
    { key: "oneworkplace", color: '#ACACAC', icon: sidebarimage3, label: "OneWorkplace", link: "/oneworkplace" },
    { key: "collaboration", color: '#ACACAC', icon: sidebarimage4, label: "Collaboration", link: "/collaboration" },
    { key: "directory", color: '#ACACAC', icon: sidebarimage5, label: "Directory", link: "/directory" },
  ];


  return (
    <>
      <div style={{ height: "100vh", position: "relative", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.5)" }}>
        <div style={{ textAlign: "center", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.5)", backgroundColor: "#76C043", paddingTop: "0.6rem" }}><img src={sidebarimages.HuberEmp} alt="Logo" /></div>
        <div style={{ textAlign: "center", position: "relative" }}>
          <img src={sidebarimages.corporateImage} style={{ width: "-moz-available" }} alt="Sidebar" />
          <div style={{
            paddingTop: "5px",
            position: "absolute", width: "100%",
            bottom: "0px",
            background: "black",
            opacity: "0.5",
          }}>
            <Title level={3} style={{
              width: "100%", color: "#FFFFFF", fontSize: "14px", fontWeight: "lighter"
            }}>
              Employee
            </Title>
          </div>
        </div>
        <div style={{ height: "100%" }} >
          {menuItems.map(item => (
            <Menu key={item.key} className="sidebarmenu" style={{ backgroundColor: "#FFFFFF", height: "100%", paddingTop: "25px" }} mode="vertical" defaultSelectedKeys={['dashboard']}>
              {menuItems.map((item, index) => {
                const activeStyle = {
                  color: '#76C043',
                  paddingLeft: index === 1 ? '6px' : '1px',
                };


                const inactiveStyle = {
                  color: 'black',
                  paddingLeft: index === 1 ? '6px' : '1px',
                };
                return (
                  <Menu.Item key={item.key} icon={<img src={item.icon} alt="Icon" />}>
                    <Link
                      style={item.link === "/booking" || item.link === "/createBooking" ? activeStyle : inactiveStyle}
                      to={item.link}
                    >
                      {item.label}
                    </Link>
                  </Menu.Item>
                )
              })}
            </Menu>
          ))}
        </div>
      </div>
    </>
  );
};


export default SideMenuBarWeb;