//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
import Loader from '../../components/src/Loader.web';
import Booking from "../../blocks/landingpage/src/Booking/BookingPage.web"

//* Routes List start
const SignUpOTP = lazy(() =>
  import('../../blocks/otp-input-confirmation/src/SignUpOTP.web')
);
const Login = lazy(() =>
  import('../../blocks/email-account-login/src/LoginEmailPassword.web')
)
const CreatePassword = lazy(() =>
  import('../../blocks/email-account-login/src/CreatePassword.web')
)
const ForgotPassword = lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPassword.web')
);
const OtpInputConfirmation = lazy(() =>
  import('../../blocks/otp-input-confirmation/src/OtpInputConfirmation.web')
);
const ScreenComingSoon = lazy(() =>
  import('../../blocks/email-account-login/src/ScreenComingSoon.web')
);
// /* Routes List End /

function WebRoutes() {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <Route
            path="/"
            exact
            render={props => <SignUpOTP {...props} />}
          />
          <Route
            path="/login"
            exact
            render={props => <Login {...props} />}
          />
          <Route
            path="/createPassword"
            exact
            render={props => <CreatePassword {...props} />}
          />
          <Route
            path="/forgotPassword"
            exact
            render={props => <ForgotPassword {...props} />}
          />
          <Route
            path="/OtpConfirmation"
            exact
            render={(props: any) => <OtpInputConfirmation {...props} />}
          />
          <Route
            path="/comingSoon"
            exact
            render={props => <ScreenComingSoon {...props} />}
            />
           <Route
            path="/booking"
            exact
            render={(props: any) => <Booking {...props} />}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}
//@ts-ignore
export default withRouter(WebRoutes);
