import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { img1, img2, img3, img5, img6 } from "../assets";


export interface Props {
    navigation: any;
    id: string;
}


interface S {
}


interface SS {
    id: any;
}
export default class BookingController extends BlockComponent<Props,
    S,
    SS> {
    constructor(props: any) {
        super(props);
    }


    imageUrls = [
        {
            title: "huber",
            img: img1
        },
        {
            title: "office",
            img: img2
        },
        {
            title: "transport",
            img: img3
        },
        {
            title: "meal",
            img: img5
        },
        {
            title: "gym",
            img: img6
        },
    ];


    handleImg = (e: any) => {
        const bookingOptions = ["huber", "transport", "office", "meal"];
        if (bookingOptions.includes(e)) { localStorage.setItem("boookingData", e) }

    }
}








