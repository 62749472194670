export const frameImage3 = require("../assets/Frame3.png");
export const frameImage4 = require("../assets/Frame4.png");
export const smallone = require("../assets/smallbuilding.png");
export const smalltwo = require("../assets/smallchair.png");
export const smallthree = require("../assets/smallpresent.png");
export const buildingImage = require("../assets/buildingIcon.png");
export const listingtype1 = require("../assets/greyCorp.png");
export const listingtype2 = require("../assets/whiteBuilidng.png");
export const sidebarimage1 = require("../assets/sidebar1.png");
export const sidebarimage2 = require("../assets/sidebar2.png");
export const sidebarimage3 = require("../assets/sidebar3.png");
export const sidebarimage4 = require("../assets/sidebar4.png");
export const sidebarimage5 = require("../assets/sidebar5.png");
export const starImage = require("../assets/Star.png");
export const arrowImage = require("../assets/downArrow.png");
export const cardstarImage = require("../assets/cardstar.png");
export const bookingHeaderImg = require("../assets/bookingHeader.png");
export const img1 = require("../assets/img1 (1).png");
export const img2 = require("../assets/img1 (2).png");
export const img3 = require("../assets/img1 (3).png")
export const img5 = require("../assets/img1 (5).png")
export const img6 = require("../assets/img6.png")
export const messageQuestion = require('../assets/messageQuestion.png')
export const notification = require('../assets/notification.png')
export const ProfileICon = require('../assets/ProfileICon.png')
export const Search = require('../assets/Search.png')
export const CorporateIcon = require('../assets/CorporateIcon.png')

export const HuberEmp = require('../assets/HuberEmp.svg')
export const corporateImage = require('../assets/corporateImage.png')
