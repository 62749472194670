import { Breadcrumb, Button, Col, Input, Layout, Row, Space } from "antd";
import React from "react"
import {  buildingImage } from "./assets";
import Title from "antd/lib/typography/Title";
import '../assets/bookingPage.css'
const headerImages = require('./assets')

const { Header } = Layout;


class Headerbar extends React.Component {


   render() {
       const pageTitle = window.location.pathname === '/booking' && 'Bookings'
       return (
           <Header style={{ background: "white", height: "auto", position: 'fixed', zIndex: 1, width: '85%' }} >
               <div style={{ paddingTop: "25px", paddingBottom: "16px" }} className="bookingheader">
                   <Row>
                       <Col span={12}>
                           <div style={{ position: "relative", bottom: "8px" }}>
                               <Title style={{ color: "#76C043", fontSize: "28px", fontWeight: "lighter" }}>
                                   {pageTitle}
                               </Title>
                               <Breadcrumb separator=">">
                                   <Breadcrumb.Item>Home</Breadcrumb.Item>
                                   <Breadcrumb.Item>
                                       <div style={{ color: '#76C043' }}>{pageTitle}</div>
                                   </Breadcrumb.Item>
                               </Breadcrumb>
                           </div>
                       </Col>
                       <Col span={12}>
                           <ul style={{
                               gap: "20px",
                               display: "flex",
                               justifyContent: "flex-end",
                               alignItems: 'center'
                           }}>
                               <li>
                                   <img src={headerImages.messageQuestion}></img>
                               </li>
                               <li>
                                   <img src={headerImages.notification}></img>
                               </li>
                               <li>
                                   <img src={headerImages.ProfileICon}></img>
                               </li>
                           </ul>
                       </Col>
                       <Col span={14}><div>
                           <Space.Compact style={{
                               width: '100%', height: "54px",
                               borderRadius: "10px",
                               overflow: "hidden",
                               border: "1px solid #b0aaaa"
                           }}>
                               <Input
                                   style={{ border: "none", borderRadius: '5px' }}
                                   defaultValue="When are you booking for ?"
                                   suffix={<img src={headerImages.Search} alt="Icon" />}
                               />
                           </Space.Compact></div></Col>
                       <Col span={2}>
                       </Col>
                       <Col span={8}><Space size={16}>
                           <Button
                               style={{ borderRadius: "10px", height: "56px", border: '1px solid #DDDDDD', boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
                               icon={<img src={buildingImage} alt="Icon" />}>
                               <label className='font-style'>
                                   Huber
                               </label>
                           </Button>
                           <Button
                               style={{ height: "56px", border: '1px solid #DDDDDD', borderRadius: "10px" }}
                               icon={<img style={{ height: "34px", width: "34px" }} src={headerImages.CorporateIcon} alt="Icon" />}
                           >
                               <label className='font-style' >
                                   Corporate
                               </label>
                           </Button>
                       </Space></Col>
                   </Row>
               </div>
           </Header>
       );
   }
}






export default Headerbar
